import '../styles/components/private-jobs.scss';

import { RouteComponentProps, Router, useLocation } from '@reach/router';
import React from 'react';

import { ResidentAddress } from '../components/marketplace/address';
import { AppointmentTime } from '../components/marketplace/appointment';
import { Header } from '../components/marketplace/components/Header';
import { HeaderNew } from '../components/marketplace/components/HeaderNew';
import { AppointmentConfirmation } from '../components/marketplace/confirmation';
import { Resident } from '../components/marketplace/resident';
import { PageNotFound } from '../components/page-not-found';
import { isNewStepProgressBarEnabled } from '../utils/feature-flags';
import { GlobalStateProvider } from '../utils/global-state';

const PageNotFoundRoute: React.FC<RouteComponentProps> = () => <PageNotFound />;

const BookingRoute: React.FC<RouteComponentProps> = () => <ResidentAddress />;

const AppointmentRoute: React.FC<RouteComponentProps> = () => (
  <AppointmentTime />
);

const ResidentRoute: React.FC<RouteComponentProps> = () => <Resident />;

const ConfirmationRoute: React.FC<RouteComponentProps<{
  appointmentId: string;
}>> = ({ appointmentId }) => (
  <AppointmentConfirmation appointmentId={appointmentId} />
);

const BookingPage = () => {
  const location = useLocation();

  const renderHeader = () => {
    if (!isNewStepProgressBarEnabled()) {
      return <Header />;
    }

    if (location.pathname === '/booking/address') {
      return null;
    }

    return <HeaderNew />;
  };

  return (
    <GlobalStateProvider>
      <div
        id="theme-root"
        className={`theme-kantan`}
        style={{
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        {renderHeader()}
        <Router basepath={`/booking`}>
          <BookingRoute path="address" />
          <ResidentRoute path="resident" />
          <AppointmentRoute path="appointment" />
          <ConfirmationRoute path="confirmation/:appointmentId" />
          <PageNotFoundRoute default />
        </Router>
      </div>
    </GlobalStateProvider>
  );
};

export default BookingPage;
