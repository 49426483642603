import '../../styles/components/marketplace.scss';

import { navigate } from '@reach/router';
import { addDays, setHours, startOfWeek } from 'date-fns';
import * as React from 'react';

import { CalendarForm as CalendarFormNew } from '../calendar/calendar-form-new/calendar-form-new';
import { usePersistedState } from './hooks/usePersistedState';

interface AppointmentValues {
  timeSlot: string | undefined;
}

const slotLabels = [
  {
    name: 'Morning',
    time: '8am-12pm',
  },
  {
    name: 'Afternoon',
    time: '12pm-4pm',
  },
  {
    name: 'Evening',
    time: '4pm-8pm',
  },
];
const startTimes = [8, 12, 16];
const endTimes = [12, 16, 20];

export const AppointmentTime: React.FC = () => {
  const [state, setState] = usePersistedState('appointmentData');
  const slotAvailability = React.useMemo(() => {
    const now = new Date();
    // Only available after 2 days or 3 days on Saturdays
    const dayOfWeek = now.getDay() || 7;
    const leadTime = dayOfWeek === 6 ? 3 : 2;
    const cutOff = addDays(now, leadTime);
    const weekStart = startOfWeek(cutOff, { weekStartsOn: 1 });
    // Show 3 weeks of availability
    const bookableSlots = [...Array(63)].map((_, i) => {
      const day = addDays(weekStart, Math.floor(i / 3));
      const slot = i % 3;
      const dayNum = day.getDay() || 7;
      const startDateTime = setHours(day, startTimes[slot]);
      const endDateTime = setHours(day, endTimes[slot]);
      // evenings and weekends are unavailable
      return {
        isAvailable: endDateTime > cutOff && slot < 2 && dayNum <= 5,
        isRatingGood: false,
        appointmentsCount: undefined,
        startDateTime,
        endDateTime,
      };
    });
    return {
      bookableSlots,
      rejectedRatingSlots: [],
    };
  }, []);
  const handleSubmit = async (value: AppointmentValues) => {
    setState({ ...state, timeSlot: value.timeSlot });
    navigate('resident');
  };

  // in case they navigate and clear the address
  if (!state.line1 || !state.postcode || !state.town) {
    alert('Address is not complete. please fill in your address again');
    navigate('address');
  }

  return (
    <section className="marketplace" data-screen="calendar_this_week">
      <CalendarFormNew
        slotError={undefined}
        isSlotsLoading={false}
        slotLabels={slotLabels}
        slotAvailability={slotAvailability}
        submitBooking={handleSubmit}
        defaultValues={{
          timeSlot: state.timeSlot,
        }}
        showKey={false}
        appointmentData={state}
      />
    </section>
  );
};
