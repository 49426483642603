import { Box } from '@material-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';

import { isNewStepProgressBarEnabled } from '../../utils/feature-flags';
import { useGlobalRef } from '../../utils/global-state';
import { AddressInputGroup } from './components/AddressInputGroup';
import { HeaderNew } from './components/HeaderNew';
import { JobTypeInputGroup } from './components/JobTypeInputGroup';
import { AppointmentData, usePersistedState } from './hooks/usePersistedState';
import { MARKETPLACE_JOB_TYPES } from './utils/jobTypes';

declare global {
  interface Window {
    dataLayer: object[];
  }
}

const useApplyParamsFromUrl = (
  setState: React.Dispatch<React.SetStateAction<AppointmentData>>,
) => {
  const hasAppliedParamsFromUrlRef = useGlobalRef('hasAppliedParamsFromUrlRef');

  if (!hasAppliedParamsFromUrlRef.current) {
    hasAppliedParamsFromUrlRef.current = true;
    applyParamsFromUrl();
  }

  function applyParamsFromUrl() {
    const q = new URLSearchParams(window.location.search);
    const jobType = q.get('jobType');
    if (jobType) {
      setState((state) => ({ ...state, jobType }));
    }
  }
};

export const ResidentAddress: React.FC = () => {
  const [state, setState] = usePersistedState('appointmentData');
  useApplyParamsFromUrl(setState);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: state,
  });

  const handleSubmit = () => {
    navigate('appointment');
  };

  const shouldDisableEverythingExceptJobType = !state.jobType;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        style={
          isNewStepProgressBarEnabled() ? { paddingTop: 10 } : { marginTop: 20 }
        }
        data-screen="resident_address"
      >
        <JobTypeInputGroup
          jobTypes={MARKETPLACE_JOB_TYPES}
          jobType={state.jobType}
          onJobTypeChange={(jobType) => setState({ ...state, jobType })}
        />

        <div
          style={
            shouldDisableEverythingExceptJobType
              ? {
                  opacity: 0.2,
                  pointerEvents: 'none',
                }
              : undefined
          }
        >
          {isNewStepProgressBarEnabled() && (
            <Box mt="20px">
              <HeaderNew />
            </Box>
          )}

          <AddressInputGroup
            state={state}
            setState={setState}
            resetForm={form.reset}
            shouldAutoFocusPostcodeLookup={
              shouldDisableEverythingExceptJobType ? false : undefined
            }
          />
        </div>
      </form>
    </FormProvider>
  );
};
