import axios from 'axios';

import { AppointmentData } from '../components/marketplace/hooks/usePersistedState';

const marketplaceURI = process.env.MARKETPLACE_URI;

///////////////////////
// MARKETPLACE
///////////////////////

type AppointmentResponse = {
  PK: string;
  SK: string;
} & AppointmentData;

export const createAppointment = async (
  data: AppointmentData,
): Promise<AppointmentResponse> => {
  const response = await axios.post<AppointmentResponse>(
    `${marketplaceURI}/api/appointments`,
    data,
  );
  return response.data;
};
