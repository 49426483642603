import { Box } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

import { isNewConfirmationEnabled } from '../../utils/feature-flags';
import { renderTimeSlot } from '../calendar/calendar-form-new/appointment-summary';
import { TickIcon } from '../icons/TickIcon';
import { AppointmentDetails } from './components/AppointmentDetails';
import {
  AppointmentData,
  defaultValue,
  usePersistedState,
} from './hooks/usePersistedState';

type ConfirmationProps = { appointmentId: string | undefined };

export const AppointmentConfirmation: React.FC<ConfirmationProps> = () => {
  const [state, setState] = usePersistedState('appointmentData');
  const [timeSlot, setTimeslot] = React.useState('');
  const [jobType, setJobType] = React.useState('');
  const [appointment, setAppointment] = React.useState<AppointmentData>();

  React.useEffect(() => {
    if (state.timeSlot) {
      setTimeslot(state.timeSlot);
      setJobType(state.jobType);
      setAppointment(state);
      setState(defaultValue);
    }
  }, [state, setState]);

  function renderOldContent() {
    return (
      <>
        <div className="confirmation__heading">
          <TickIcon />
          <h1 className="confirmation__heading__text success">
            Your service has been booked
          </h1>
        </div>
        <p>You&apos;ll receive an email confirming all the details shortly.</p>

        <h2 className="heading2">Appointment Time</h2>
        <p>{timeSlot && renderTimeSlot(timeSlot)}</p>
      </>
    );
  }

  function renderNewContent() {
    const emailLink = (
      <a
        className="marketplace-link"
        href="mailto:support@kantan.tech"
        target="_blank"
        rel="noreferrer"
      >
        support@kantan.tech
      </a>
    );

    const phoneLink = (
      <a className="marketplace-link" href="tel:+44-330-818-8759">
        +44 330 818 8759
      </a>
    );

    return (
      <>
        <Box className="marketplace-heading" mt="20px" mb="15px">
          <span className="marketplace-heading__accent">
            Thank you for trusting Kantan
          </span>{' '}
          for your booking!
        </Box>

        {appointment && <AppointmentDetails appointmentData={appointment} />}

        <Box
          mt="20px"
          color="#292B2E"
          fontSize="14px"
          lineHeight="22px"
          letterSpacing="-0.02em"
        >
          <Box component="p" mb="20px">
            Please check your inbox for an email with your appointment
            confirmation and detailed information.
          </Box>

          <Box component="p" fontWeight="700">
            If you don&apos;t receive an email:
          </Box>

          <Box
            component="ol"
            style={{ listStyle: 'decimal', paddingLeft: '20px' }}
          >
            <li>Check your spam folder</li>
            <li>
              Get in touch with us on {emailLink} or call at {phoneLink}
            </li>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <div
      className={clsx(!isNewConfirmationEnabled() && 'confirmation')}
      data-screen="confirmation"
      data-jobType={jobType}
    >
      {isNewConfirmationEnabled() ? renderNewContent() : renderOldContent()}
    </div>
  );
};
