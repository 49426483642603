import { get } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import { FieldError } from 'react-hook-form/dist/types/form';

import { isNotNullOrUndefined } from '../../../utils/useNotNullOrUndefined';
import { CheckboxInput } from './CheckboxInput';

interface CheckboxFieldProps {
  name: string;
  label: React.ReactNode;
  required?: boolean;
  requiredMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxField = ({
  name,
  label,
  required,
  requiredMessage,
  onChange,
}: CheckboxFieldProps) => {
  const form = useFormContext();
  const error = get(form.errors, name) as FieldError | undefined;

  const validateField = (value: unknown) => {
    if (required) {
      if (!value) {
        return requiredMessage ?? 'Required';
      }
    }
  };

  return (
    <Controller
      name={name}
      type
      control={form.control}
      rules={{ validate: validateField }}
      render={(inputProps) => (
        <CheckboxInput
          name={name}
          {...inputProps}
          checked={inputProps.value}
          label={label}
          required={required}
          invalid={isNotNullOrUndefined(error)}
          helperText={error?.message}
          onChange={(e) => {
            const checked = e.target.checked;
            onChange?.(e);
            inputProps.onChange(checked);
          }}
        />
      )}
    />
  );
};
