import { navigate, Redirect } from '@reach/router';
import * as React from 'react';

import { createAppointment } from '../../services/marketplace-client';
import { ResidentFormNew } from './components/ResidentFormNew';
import { AppointmentData, usePersistedState } from './hooks/usePersistedState';

export const Resident: React.FC = () => {
  const [state, setState] = usePersistedState('appointmentData');
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>();

  const handleSubmit = async () => {
    setSubmitting(true);
    setSubmitError(undefined);

    try {
      const appointment = await createAppointment(state);
      navigate(`confirmation\\${appointment.PK}\\`);
    } catch (e) {
      setSubmitError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (nextState: AppointmentData) => {
    setState(nextState);
    setSubmitError(undefined);
  };

  if (!state.timeSlot) {
    return <Redirect to="/booking/address" />;
  }

  return (
    <ResidentFormNew
      state={state}
      onChange={handleChange}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      submitError={submitError}
    />
  );
};
