import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import React from 'react';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: '#DAE0ED',
  },
  bar: {
    borderRadius: 2,
    backgroundColor: '#0B4ED5',
  },
}))(LinearProgress);

const sectionProgress: Record<string, number> = {
  '/booking/address': 33,
  '/booking/appointment': 66,
  '/booking/resident': 100,
};

export const Header = () => {
  const route = useLocation();

  return (
    <div className="marketplace__header">
      <div>
        <BorderLinearProgress
          variant="determinate"
          value={sectionProgress[route.pathname]}
          className="marketplace__headerprogress"
        />
        <Grid container spacing={3}>
          <Grid item xs>
            <h3
              style={{
                textAlign: 'left',
              }}
              className={
                '/booking/address' === route.pathname
                  ? 'heading3-selected'
                  : 'heading3'
              }
            >
              Enter address
            </h3>
          </Grid>
          <Grid item xs>
            <h3
              className={
                '/booking/appointment' === route.pathname
                  ? 'heading3-selected'
                  : 'heading3'
              }
            >
              Select time
            </h3>
          </Grid>
          <Grid item xs>
            <h3
              style={{
                textAlign: 'right',
              }}
              className={
                '/booking/resident' === route.pathname
                  ? 'heading3-selected'
                  : 'heading3'
              }
            >
              Submit
            </h3>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
