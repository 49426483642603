import '../../../styles/components/marketplace.scss';

import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';

import { arePromosAndTermsAndConditionsEnabled } from '../../../utils/feature-flags';
import { Button } from '../../button';
import { AppointmentData } from '../hooks/usePersistedState';
import { AppointmentDetails } from './AppointmentDetails';
import { CheckboxField } from './CheckboxField';
import { TextField } from './TextField';

const TERMS_AND_CONDITIONS_URL = 'https://kantan.co.uk/terms-and-conditions';

interface ResidentFormNewProps {
  state: AppointmentData;
  onChange: (nextState: AppointmentData) => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  submitError?: string;
}

export const ResidentFormNew = ({
  state,
  onChange,
  onSubmit,
  isSubmitting,
  submitError,
}: ResidentFormNewProps) => {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: state,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    onChange({ ...state, [event.target.name]: value });
  };

  useEffect(() => {
    // TODO: move to `defaultValue` in src/components/marketplace/hooks/usePersistedState.tsx
    // when the new resident form is enabled for everyone
    onChange({
      ...state,
      acceptPromos: state.acceptPromos ?? false,
      acceptTerms: state.acceptTerms ?? false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...form}>
      <form
        data-screen="resident_details"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Box className="marketplace-heading" mt="20px" mb="15px">
          Fill in your details to{' '}
          <span className="marketplace-heading__accent">
            submit your booking
          </span>
        </Box>

        <TextField
          name="fullName"
          label="Full name"
          autoComplete="name"
          required
          onChange={handleChange}
        />
        <TextField
          name="email"
          label="Email address"
          type="email"
          autoComplete="email"
          required
          onChange={handleChange}
        />
        <TextField
          name="phone"
          label="Phone number"
          type="tel"
          autoComplete="mobile"
          required
          onChange={handleChange}
        />

        <AppointmentDetails appointmentData={state} />

        {arePromosAndTermsAndConditionsEnabled() && (
          <Box mt="15px">
            <CheckboxField
              name="acceptPromos"
              label="I want to be contacted by Kantan about future offers and promotions"
              onChange={handleChange}
            />
            <CheckboxField
              name="acceptTerms"
              label={
                <>
                  I agree with the{' '}
                  <a
                    className="marketplace-link"
                    target="_blank"
                    rel="noreferrer"
                    href={TERMS_AND_CONDITIONS_URL}
                  >
                    Terms and Conditions
                  </a>
                </>
              }
              required
              onChange={handleChange}
            />
          </Box>
        )}

        <div className="marketplace-submit">
          {submitError && (
            <div className="marketplace-submit__error">{submitError}</div>
          )}

          <Button
            className="marketplace-submit__button"
            variant="primary"
            type="submit"
            busy={isSubmitting}
          >
            Submit booking
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
