import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { CheckboxIcon } from '../../icons/CheckboxIcon';
import { CheckboxSelectedOutlineIcon } from '../../icons/CheckboxSelectedOutlineIcon';

const useStyles = makeStyles({
  label: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292B2E',
  },
  asterisk: {
    color: '#DD2417',
  },
  helperText: {
    marginLeft: '31px',
    marginTop: '-8px',
    marginBottom: '-4px',
    fontSize: '12px',
    lineHeight: '18px',
  },
  invalid: {
    '& $helperText': {
      color: '#DD2417',
    },
  },
});

interface CheckboxInputProps {
  name?: string;
  label: React.ReactNode;
  checked?: boolean;
  required?: boolean;
  invalid?: boolean;
  helperText?: string;
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxInput = ({
  name,
  label,
  checked,
  required,
  invalid,
  helperText,
  onBlur,
  onChange,
}: CheckboxInputProps) => {
  const classes = useStyles();

  return (
    <FormControl
      classes={{ root: clsx(invalid && classes.invalid) }}
      required={required}
      error={invalid}
    >
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={checked || false}
            color="primary"
            icon={<CheckboxIcon color="#DADADA" />}
            checkedIcon={<CheckboxSelectedOutlineIcon />}
            onBlur={onBlur}
            onChange={onChange}
          />
        }
        label={
          <>
            {label}
            {required && <span className={classes.asterisk}> *</span>}
          </>
        }
        classes={{ label: classes.label }}
      />
      {helperText && (
        <FormHelperText classes={{ root: classes.helperText }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
